import React from 'react';
import { Button } from '../ButtonElements';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-5.svg';


import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Additional Projects</ServicesH1>
      
        
          
      <ServicesWrapper>
        
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>A Will Out</ServicesH2>
          <ServicesP>
            Systems Programmer and Level Designer for a game focusing on mental health. Developed in the Phaser
            Engine and JavaScript.

          <br/>
          <br/>
          </ServicesP>
          
          <Button
          as="a" 
          href="https://github.com/Alegriaa/120FinalGame"
            to='signup'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            
          >
            View Project 
          </Button>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Slug Life</ServicesH2>
          <ServicesP>
            An Endless Runner developed in Phaser and JavaScript. Based on our expiriences of being a student during a pandemic.
          
            <br/>
          <br/>
          </ServicesP>
          <Button
          as="a" 
          href="https://github.com/Alegriaa/SlugLife"
            to='/signin'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            
          >
            View Project 
          </Button>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>The Witch</ServicesH2>
          <ServicesP>
          Worked with Professor Elizabeth Swensen 
          in Unity and C# in order to transfer a preexisting game in HTML into Unity.

          <br/>
          <br/>
          </ServicesP>
          
        </ServicesCard>
       <br/>
     <br/>
     <br/>
    
          

        
      </ServicesWrapper>
     
     
    </ServicesContainer>
    
  );
};

export default Services;
