import React from 'react';

import ScrollToTop from '../components/ScrollToTop';
import InfoSection from '../components/InfoSection';
import Sidebar from '../components/Sidebar';
import { homeObjOne } from '../components/InfoSection/Data';

function SigninPage() {
  return (
    <>
      <ScrollToTop />
      <InfoSection {...homeObjOne} />
      <Sidebar/>
    </>
  );
}

export default SigninPage;
