export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Recent Project',
  headline: 'Mocha Magic',
  description:
    'I worked as a Systems Programmer for a year long senior capstone project. I also helped run and manage the QA team throughout the project.',
  buttonLabel: 'Launch on itch.io',
  hrefVariable:"https://catpawgames.itch.io/mocha-magic",
  imgStart: false,
  img: require('../../images/mocha-magic-circle.png'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Current Project',
  headline: 'Street Level Code',
  description:
    'Front-End Web Developer for a website focusing on teaching the basics of programming and web development. Anticipated launch of Septempber 2021.',
  buttonLabel: 'Coming Soon...',
  hrefVariable:"",
  imgStart: true,
  img: require('../../images/slc-web-logo.png'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true,
  
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'More about me',
  headline: 'Graduated Spring 2021 from UC Santa Cruz with a 3.9 GPA',
  description:
    "After attending Santa Barbara City College for Computer Science, I graduated from UC Santa Cruz with a Bachelor's degree in Art & Design: Games and Playable Media.",
  buttonLabel: 'View Resume',
  hrefVariable:"https://drive.google.com/drive/folders/1RMYYdAIWOjCOzZw16NAbTzBgFzoIHAeF?usp=sharing",
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
